import { TCall, TSubmissionStatus } from "__generated__/types";

/**
 * Returns true if the call allows editing of submissions and
 * the call is still open
 */
export function checkIsCallEditable(
  call?: Pick<TCall, "submissionEndDate" | "allowsRevocation">,
) {
  if (!call) return null;

  const isOpen = call.submissionEndDate > new Date().toISOString();
  const isEditable = call.allowsRevocation && isOpen;
  return isEditable;
}

/**
 * Checks if the submission is in edit mode for a given call.
 *
 * A submission is considered to be in edit mode if:
 * 1. The submission status is explicitly "InEdit".
 * 2. The call is still open and allows revocation and the submission status
 *    is "Submitted".
 */
export function checkIsSubmissionInEditMode(
  submission: { status: TSubmissionStatus | null | undefined } | null = {
    status: undefined,
  },
  call?: Pick<TCall, "submissionEndDate" | "allowsRevocation">,
) {
  if (!submission) return null;

  if (submission.status === TSubmissionStatus.InEdit) {
    return true;
  }

  // This check is needed because a submission formally enters "in-edit" mode
  // only after edits are submitted by the user. However, when users return to the
  // submission, the edit mode UI is displayed even before they submit any edits.
  const isCallEditable = checkIsCallEditable(call);
  if (isCallEditable === null) return null;

  const isSubmitted = submission.status === TSubmissionStatus.Submitted;
  const isEditMode = isCallEditable && isSubmitted;
  return isEditMode;
}
